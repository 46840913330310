import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/analytics'
import 'firebase/compat/functions'

const isBeta = process.env.VUE_APP_ENV === 'beta';

var firebaseConfig = {
  apiKey: isBeta ? process.env.VUE_APP_FIREBASE_BETA_API_KEY : process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: isBeta ? process.env.VUE_APP_FIREBASE_BETA_AUTH_DOMAIN : process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: isBeta ? process.env.VUE_APP_FIREBASE_BETA_PROJECT_ID : process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: isBeta ? process.env.VUE_APP_FIREBASE_BETA_STORAGE_BUCKET : process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: isBeta ? process.env.VUE_APP_FIREBASE_BETA_MESSAGING_SENDER_ID : process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: isBeta ? process.env.VUE_APP_FIREBASE_BETA_APP_ID : process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: isBeta ? process.env.VUE_APP_FIREBASE_BETA_MEASUREMENT_ID : process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export default firebase;