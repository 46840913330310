import { createWebHistory, createRouter } from "vue-router";

const routes =  [
  {
    path: "/",
    name: "start",
    component: () => import("./pages/start")
  },
  {
    path: "/neuerKunde",
    name: "neuerKunde",
    component: () => import("./pages/partner/neuerKunde")
  },
  {
    path: '/vertrag/:id',
    name: 'Vertrag',
    component: () => import("./pages/vertragV2"),
    // beforeEnter: authGuard,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/', // Weiterleitung auf die Startseite
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;